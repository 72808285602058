@import "./mixins";
@import "./variables";

// plugins
@import "./bootstrap/bootstrap";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import "~react-modal-video/scss/modal-video.scss";

//@import './fonts';
@import "./base";
@import "./sections";
@import "./form";

// top
//@import './layout/hamburger';
//@import './layout/top';
@import "./layout/top-navbar";

// bottom
@import "./layout/bottomPanel";
@import "./layout/bottomMenu";

@import "./cms/cms-site";

// modules
@import "./modules/breadcrumb";
@import "./modules/rotatingBanner";
@import "./modules/bannerSize";
@import "./modules/pageTiles";
//@import './modules/blogTiles';
@import "./modules/slider";
@import "./modules/multiColumnPanel";

// components
@import "../components";

@import "./site/index";

//aos
@import "~aos/src/sass/aos";

@media print {
  @import "./print";
}

@import "./youtubeNew.scss";
@import "./modal.scss";
@import "./verticalTilesPanel.scss";

//banner-text-bar
section.banner-text-bar {
  .htmlPanel {
    p {
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

.choose-file-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  label.choose-file-label {
    background-color: white;
    margin-bottom: 0px !important;
    //font-family: $family-button;
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: 600;
    //color: #444;
  }
  div.file__attach__text {
    margin-left: 1rem;
    //color: white;
  }
}

body:not(.cmsedit) {
  div.cms_item.desktop-ig-feed {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  div.cms_item.mobile-ig-feed {
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.google-my-maps-panel .container {
  max-width: 100%;
  padding: 0;
}
.google-my-maps {
  width: 100%;
  max-width: 100%;
}
.google-my-maps iframe {
  width: 100%;
}
.pageButton {
  color: #000;
  background: #fff;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 0.7rem 2rem;
  font-size: 0.7rem;
  font-weight: 400;
  @media screen and (max-width: 578px) {
    padding: 0.5rem 0.6rem;
  }
}
.product-slider .container {
  @media screen and (min-width: 1200px) {
    max-width: 1000px;
  }
  @media screen and (min-width: 1600px) {
    max-width: 1400px;
  }
  @media screen and (min-width: 2000px) {
    max-width: 1600px;
  }
}

.product__container:hover .product__image__overlay {
  opacity: 1;
  transform: translateY(0);
  transition: 0.3s ease all;
  @media screen and (max-width: 576px) {
    opacity: 0;
  }
}
.productListContainer {
  position: relative;
  overflow: hidden;
  box-shadow: none;
  padding: 2.5rem 0 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 576px) {
    justify-content: space-between;
  }
}
.product__image__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  background: #181818;
  top: 0;
  opacity: 0;
  transform: translateY(100%);
  transition: 0.3s ease all;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem 2rem;
  color: #fff;
  @media screen and (max-width: 576px) {
    display: none;
  }
}
.btn_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.btn_container .addCart_btn {
  color: black;
  background: #fff;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 0.8rem 0.6rem;
  font-size: 0.8rem;
  font-weight: 400;
  &:focus,
  &:hover {
    background: $primary-color;
    color: #fff;
    text-decoration: none;
    border: 1px solid $primary-color;
  }
}
.product__image__overlay .btn_container .button.learnMore_button {
  background: #fff;
  padding: 0.8rem 0.6rem;
  font-size: 0.8rem;
  &:focus,
  &:hover {
    background: $primary-color;
    color: #fff;
    text-decoration: none;
    border: 1px solid $primary-color;
  }
}

.product__image__container {
  width: 100%;
}
.product-slider .container .productListName {
  padding: 2rem 20px 0.8rem 20px;
  @media screen and (max-width: 576px) {
    font-size: 1rem;
  }
}
.product_cont .productListName {
  padding: 1rem 1rem 0.8rem 1rem;
  margin-bottom: 0;
}
.productListPrice {
  font-size: 0.8rem;
  text-align: center;
  font-weight: 500;
  padding: 0 0.5rem 0.8rem 0.5rem;
  color: #404040;
}
.productListButton {
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 576px) {
    display: none;
  }
}
.product_cont {
  @media screen and (min-width: 1200px) {
    max-width: 1000px !important;
  }
  @media screen and (min-width: 1600px) {
    max-width: 1400px !important;
  }
  @media screen and (min-width: 2000px) {
    max-width: 1600px !important;
  }
}
.product__tile__item {
  // margin-top: 1rem !important;
  // margin-bottom: 1rem !important;
  // @media screen and (max-width: 576px) {
  //   margin: 0 !important;
  // }
  margin: 0 !important;
}
.product_cont .product__tile__item {
  @media screen and (max-width: 576px) {
    max-width: 50%;
    flex: 0 0 50%;
  }
}
.multiColumnPanelv2 a.button {
  margin-top: 1rem;
  display: inline-block;
  @media screen and (max-width: 576px) {
    margin-top: 0.5rem;
  }
}
.tes_image {
  margin: auto;
}
.product__image__container .product__tile__bg {
  @media screen and (max-width: 576px) {
    padding-top: 350px;
  }
}
.ecm .product__image__container .product__tile__bg {
  padding-top: 250px;
  @media screen and (max-width: 576px) {
    padding-top: 135px;
  }
}

.pl-5 {
  padding-left: 5rem;
}

@media screen and (max-width: 768px) {
  .pl-5 {
    padding-left: 0;
  }
}

#get-in-touch a.button {
  font-size: 1.3rem;
}