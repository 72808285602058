.pageTiles3 {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 2rem -10px;
  overflow: hidden;
}

.pageTiles3 .pageTile3__tile {
  max-width: 100%;

  padding: 10px;

  position: relative;

  flex: 0 0 100%;
}

// .pageTile3__tile:nth-of-type(even) .tile3__overlay {
//   transform: scaleX(-1);
// }

// .pageTile3__tile:nth-of-type(even) .tile3__overlay .tile3__details {
//   transform: scaleX(-1);
//   // text-align: right;
//   padding-right: 0;
//   padding-left: 3rem;
// }

// .pageTile3__tile:nth-of-type(even) .tile3__btn {
//   //   margin-left: auto;
//   margin-right: 0;
// }

.pageTiles3 .pageTile3__tile .pageTile3__bg {
  width: 100%;
  height: 560px;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.pageTiles3 .tile3__overlay {
  width: 100%;
  height: 560px;
  background-image: url("./Big_Tile_gradient_Cream_w_Line2_2.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.tile3__details {
  width: 40%;
  height: 100%;

  margin-left: auto;
  padding-right: 3rem;

  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.tile3__details > * {
  margin-bottom: 1rem;
}

.tile3__btn {
  background-color: transparent;
  color: black;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 0.8rem 2rem;
  font-size: 0.8rem;
  font-weight: 400;
  margin-top: 1rem;
  &:hover {
    border: 1px solid #af5f27;
    color: #fff;
    background-color: #af5f27;
    text-decoration: none;
  }
}

.tile3__title {
  font-size: 36px;
  color: #af5f27;
  font-weight: 400;
}

@media screen and (max-width: 992px) {
  .pageTiles3 .tile3__bg {
    height: 440px;
  }
}

@media screen and (max-width: 768px) {
  .pageTiles3 .pageTile3__tile .pageTile3__bg {
    height: 560px;
  }

  // .pageTiles3 .pageTile3__tile:nth-of-type(even) .tile3__overlay {
  //   transform: scaleX(1);
  // }

  // .pageTiles3
  //   .pageTile3__tile:nth-of-type(even)
  //   .tile3__overlay
  //   .tile3__details {
  //   transform: scaleX(1);
  //   text-align: center;
  //   padding-inline: 0;
  // }

  // .pageTiles3 .pageTile3__tile:nth-of-type(even) .tile3__btn {
  //   margin-left: 0;
  //   margin-right: 0;
  // }

  .pageTiles3 .tile3__overlay {
    background-image: url("./Small_Mobile_Tile_gradient_Cream_W_Line2_2.svg");
    background-position: 0 200px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .pageTiles3 .tile3__details {
    width: 100%;
    text-align: left;
    padding-top: 18rem;
    padding-left: 0;
    padding-right: 0;
  }

  .pageTiles3 .tile3__title {
    font-size: 25px;
    text-align: center;
    width: 100%;
  }

  .pageTiles3 .tile3__body {
    padding-inline: 1rem;
    margin-bottom: 1rem;
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  .pageTiles3 .tile3__btn {
    align-self: center;
    margin: 0;
  }
}
