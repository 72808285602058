// overriding bootstrap variables in _boostrap-override.scss
@font-face {
  font-family: "Harmonia Sans Mono";
  src: url("./fonts/Harmonia-Sans-Mono-W01-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Harmonia Sans";
  src: url("./fonts/Harmonia-Sans-W01-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Harmonia Sans";
  src: url("./fonts/Harmonia-Sans-W01-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Harmonia Sans";
  src: url("./fonts/Harmonia-Sans-W06-Condensed.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

$family-base: "Harmonia Sans";
$family-header: "Harmonia Sans";

// font-size
$font-size-desktop: 18px;
$font-size-mobile: 16px;

$weight-thin: 100;
$weight-light: 300;
$weight-regular: 400;
$weight-normal: $weight-regular;
$weight-bold: 700;

// colors - sister colours from bootrap
$color-blue: #007bff;
$color-red: #c20f2f;
$color-orange: #fd7e14;
$color-green: #28a745;

// more colors
$color-gold: #faa222;
$color-dark-gray: #252525;
$color-dark-blue: #222d64;
$color-mid-blue: #5c6594;
$color-blue-main: #3d6a7d;

// site colors
$primary-color: #af5f27;
$font-color: #404040;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color: #ebebeb;
$link-color: $primary-color;
$link-hover-color: darken($primary-color, 10);

// top-bar
//$top-bar-border: (mobile: 5px, desktop: 12px);
//$top-bar-height: (mobile: 1.6rem, desktop: 2rem);
$top-bar-border: (
  mobile: 5px,
  desktop: 12px,
);
//$top-bar-height: (mobile: 2.7rem, small-device:2.8rem, tablet:3.5rem, desktop: 3.1rem);
$top-bar-height: (
  // mobile: 44px,
  mobile: 75px,
  // small-device: 82px,
  small-device: 150px,
  // tablet: 82px,
  tablet: 150px,
  // desktop: 82px,
  desktop: 150px
);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;
