.verticalTilesPanel_container {
  max-width: 1200px;
  margin: auto;
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.wrapper {
  max-width: 100%;
  margin-bottom: 2rem;
  height: 30rem;
  position: relative;
  @media screen and (min-width: 768px) {
    height: 25rem;
    flex: 0 0 31%;
    max-width: 31%;
    position: relative;
  }
}
.vt_overlay {
  background-image: url("../_scss/modules/pageTiles/Small_Mobile_Tile_gradient_Cream_W_Line2_2.svg");
  background-position: 0 200px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
}
.wrapper img {
  max-width: 100%;
  height: 100%;
  width: 100%;
}
.wrapper .img_bg {
  height: 50%;
}
.wrapper .des {
  height: 50%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.des_image {
  transform: scaleY(-1);
  height: 100%;
}
.des_content {
  position: absolute;
  top: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 1rem 0 1rem;
}
.content {
  font-size: 0.9rem;
  line-height: 1.3;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-align: left;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
}
.des .vt_title {
  color: #af5f27;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0.5rem 0;
}
